import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyAjDu3C3x7J_lwLATImtYSX02moAYTpHVk",
  authDomain: "to-do-daca9.firebaseapp.com",
  projectId: "to-do-daca9",
  storageBucket: "to-do-daca9.appspot.com",
  messagingSenderId: "118467006723",
  appId: "1:118467006723:web:b8dfa89e9d9a5d72c71936"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);