import Signup from "./components/Signup";
import Login from "./components/Login";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Main from "./components/Main";
import UserAuthState from './contexts/UserAuthState'
import ProtectedRoute from "./components/ProtectedRoute";


function App() {
  return (
    <div className="app">
      <UserAuthState>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/home" element={<ProtectedRoute><Main /></ProtectedRoute>} />
            <Route path="/signup" element={<Signup />} />
          </Routes>
        </BrowserRouter>
      </UserAuthState>
    </div>
  );
}

export default App;