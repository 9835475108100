import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Signup.css'
import UserAuthContext from '../contexts/UserAuthContext'


const Signup = () => {
	const context = useContext(UserAuthContext)
	const { signup } = context

	const navigate = useNavigate()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState("")
	const [credentials, setCredentials] = useState({ email: "", password: "" })
	const onChange = (e) => {
		setCredentials({ ...credentials, [e.target.name]: e.target.value })
	}

	const onSubmit = async (e) => {
		e.preventDefault()
		try {
			setLoading(true)
			setError("")
			await signup(credentials.email, credentials.password)
			navigate("/")
		}
		catch (err) {
			if (err.message.includes("auth/email-already-in-use")) {
				setError("Please use a different email.")
			}
			else {
				setError(err.message)
			}
		}
		setLoading(false)
	}

	return (
		<div className='container-main-signup'>
			<div className="container">
				<h1 className='signup-h1'>Sign Up</h1>
				{error && <span className='error-message'>{error}</span>}
				<form className='signup-form' onSubmit={onSubmit}>
					<input className='signup-input' value={credentials.email} onChange={onChange} type="email" id='email' placeholder='Email' name='email' required />
					<input className='signup-input' value={credentials.password} onChange={onChange} type="password" name="password" placeholder='Password' id="password" minLength={6} required />
					<button className='signup-btn' disabled={loading} type='submit'>Sign Up</button>
				</form>
				<p className='signup-login'>Already have an account? <Link to="/">Login</Link></p>
			</div >
		</div>
	)
}

export default Signup