import UserAuthContext from './UserAuthContext'
import { useEffect, useState } from 'react'
import { auth } from '../firebase'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { doc, collection, query, where, addDoc, getDocs, updateDoc, deleteDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase"


const UserAuthState = (props) => {
    const [user, setUser] = useState()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser)
            setLoading(false)
        })
        return unsubscribe
    }, [])

    const signup = async (email, password) => {
        await createUserWithEmailAndPassword(auth, email, password)
    }

    const login = async (email, password) => {
        await signInWithEmailAndPassword(auth, email, password)
    }

    const addUserTask = async (user, task) => {
        await addDoc(collection(db, "tasks"), {
            author: user.uid,
            task: task.task,
            complete: false,
            timestamp: serverTimestamp()
        });
    }

    const tempTasks = []
    const [tasks, setTasks] = useState(tempTasks)
    const fetchUserTasks = async (user) => {
        const q = query(collection(db, "tasks"), where("author", "==", user.uid))
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            tempTasks.push({ doc_id: doc.id, task: doc.data().task, complete: doc.data().complete })
        });
        setTasks(tempTasks)
    }
    const editUserTask = async (id, complete) => {
        await updateDoc(doc(db, "tasks", id), {
            complete: !complete
        });
    }

    const deleteUserTask = async (id) => {
        await deleteDoc(doc(db, "tasks", id));
    }
    return (
        <UserAuthContext.Provider value={{ user, signup, login, addUserTask, fetchUserTasks, editUserTask, deleteUserTask, tasks, setTasks }}>
            {!loading && props.children}
        </UserAuthContext.Provider>
    )
}

export default UserAuthState