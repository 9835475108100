import React, { useState, useContext, useEffect } from 'react'
import './Main.css'
import UserAuthContext from '../contexts/UserAuthContext'
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../firebase"



const Main = () => {
    const context = useContext(UserAuthContext)
    const { user, addUserTask, fetchUserTasks, editUserTask, deleteUserTask, tasks, setTasks } = context

    const [task, setTask] = useState({ task: "" })

    useEffect(() => {
        // fetchUserTasks(user)
        const q = query(collection(db, "tasks"), where("author", "==", user.uid))
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let list = []
            querySnapshot.docs.forEach((doc) => {
                list.push({ doc_id: doc.id, ...doc.data() })
            })
            setTasks(list)
        });

        return () => {
            unsubscribe()
        }
    }, [])

    // ##########################
    // "multiDimensionalUnique" function created with the help of Stack Overflow question
    // https://stackoverflow.com/questions/20339466/how-to-remove-duplicates-from-a-two-dimensional-array
    // Question by Michał Kalinowski:
    // https://stackoverflow.com/users/2432403/micha%c5%82-kalinowski
    // Answer by Andrew Stephen:
    // https://stackoverflow.com/users/909135/stephen

    const multiDimensionalUnique = (arr) => {
        let uniques = [];
        let itemsFound = {};
        for (let i = 0, l = arr.length; i < l; i++) {
            let stringified = JSON.stringify(arr[i]);
            if (itemsFound[stringified]) { continue; }
            uniques.push(arr[i]);
            itemsFound[stringified] = true;
        }
        return uniques;

    }
    // ##########################


    return (
        <div className="main-container">
            <h1 className='main-container-h1'>To-Do App</h1>
            <div className="main__container">
                <div className="main-input-container">
                    <input className='main-input' onChange={(e) => { setTask({ task: e.target.value }) }} value={task.task} type="text" id='task' name='task' minLength={1} required />
                    <button className='main-add-btn' onClick={() => { addUserTask(user, task); setTask({ task: "" }) }} disabled={task.task.length === 0}>Add</button>
                </div>
                <div className="main-tasks-container">
                    {multiDimensionalUnique(tasks).map(task => (
                        <div className={`task-item ${task.complete ? "task-done" : ""}`} key={task.doc_id}>
                            <span className='task-item-span'>{task.task}</span>
                            <div className='task-item'>
                                <button className={`checked ${task.complete ? "input-checked" : ""}`} onClick={() => (editUserTask(task.doc_id, task.complete))}></button>
                                <button className={'delete-btn'} onClick={() => (deleteUserTask(task.doc_id))}>X</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Main