import React, { useContext } from 'react'
import UserAuthContext from '../contexts/UserAuthContext'
import { Navigate } from 'react-router-dom'

const ProtectedRoute = ({ children }) => {
    const context = useContext(UserAuthContext)
    const { user } = context
    return user ? children : <Navigate to="/" />
}

export default ProtectedRoute