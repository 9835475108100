import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Login.css'
import UserAuthContext from '../contexts/UserAuthContext'


const Login = () => {
    const context = useContext(UserAuthContext)
    const { login } = context

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [credentials, setCredentials] = useState({ email: "", password: "" })
    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            setError("")
            await login(credentials.email, credentials.password)
            navigate("/home")
        } catch (err) {
            if (err.message.includes("auth/wrong-password") || (err.message.includes("auth/user-not-found")) || (err.message.includes("auth/invalid-email"))) {
                setError("Please enter correct credentials.")
            }
            else {
                setError(err.message)
            }
        }
        setLoading(false)
    }
    return (
        <div className='container-main-login'>
            <div className="container">
                <h1 className='login-h1'>Login</h1>
				{error && <span className='error-message'>{error}</span>}
                <form className='login-form' onSubmit={onSubmit}>
                    <input className='login-input' value={credentials.email} onChange={onChange} type="email" id='email' placeholder='Email' name='email' required />
                    <input className='login-input' value={credentials.password} onChange={onChange} type="password" placeholder='Password' name="password" id="password" required />
                    <button className='login-btn' disabled={loading} type='submit'>Login</button>
                </form>
                <p className='login-signup'>Don't have an account? <Link to="/signup">Signup</Link></p>
            </div>
        </div>
    )
}

export default Login